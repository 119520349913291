import React from 'react';
import { sectionComponents } from '../../utils/allSectionsUtils';
import bgSchemas from '../organisms/backgroundSchemaNames';

// This takes all the twoColumnSections and alternates the
// position of their text blocks
const setTextPositionOfTwoColumn = sections => {
  let lastTextBlockPosition = 'left';
  return sections.map(section => {
    if (section._type === 'twoColumnSectionV2') {
      const sectionToReturn = JSON.parse(JSON.stringify(section));
      sectionToReturn.textBlockPosition = lastTextBlockPosition;
      lastTextBlockPosition =
        lastTextBlockPosition === 'left' ? 'right' : 'left';
      return sectionToReturn;
    }
    return section;
  });
};

const bgArrayToProperty = sections => {
  return sections.map(section => {
    return {
      ...section,
      backgroundSection:
        section.sectionStyles &&
        section.sectionStyles.backgroundSection &&
        section.sectionStyles.backgroundSection[0]
          ? section.sectionStyles.backgroundSection[0]
          : {
              _type: 'noBackground',
            },
    };
  });
};

export const renderPageSections = (pageSections, contextData) => {
  if (!pageSections) {
    return null;
  }

  try {
    // TODO: make this work with TwoColumnSectionV1???
    const configuredPageSections = bgArrayToProperty(
      setTextPositionOfTwoColumn(pageSections)
    );

    const backgroundSections = configuredPageSections.reduce(
      (acc, section, index, srcArray) => {
        if (
          index > 0 &&
          srcArray[index - 1].backgroundSection &&
          acc[acc.length - 1].backgroundSection._type !== 'noBackground' &&
          section.backgroundSection
        ) {
          // customBackgrounds are only the same if they have the
          // same sanity id, the other backgrounds (GrayBox, etc) are
          // unique by their _type
          const prevBackground =
            srcArray[index - 1].backgroundSection._type === 'customBackground'
              ? srcArray[index - 1].backgroundSection.id
              : srcArray[index - 1].backgroundSection._type;
          const currBackground =
            section.backgroundSection._type === 'customBackground'
              ? section.backgroundSection.id
              : section.backgroundSection._type;

          // if adjacent sections have the same background, combine them
          if (prevBackground === currBackground) {
            const prevValue = acc[acc.length - 1];
            const accMinusPrevValue = acc.slice(0, acc.length - 1);

            return accMinusPrevValue.concat({
              ...prevValue,
              contentSections: prevValue.contentSections.concat(section),
            });
          }
        }

        return acc.concat({
          backgroundSection: section.backgroundSection,
          contentSections: [section],
        });
      },
      []
    );

    const backgroundSectionComponents = backgroundSections.map(bgSection => {
      const MyBackground = bgSchemas[bgSection.backgroundSection._type];
      return (
        <MyBackground {...bgSection.backgroundSection}>
          {bgSection.contentSections.map(contentSection => {
            const MyContentSection = sectionComponents[contentSection._type];
            return (
              <MyContentSection key={contentSection._key} {...contentSection} />
            );
          })}
        </MyBackground>
      );
    });

    return backgroundSectionComponents;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      'Section component type not found. Check the `sectionSchemas` in `allSectionsUtils`. ',
      error,
      pageSections,
      contextData
    );
  }
  return null;
};

export default {
  renderPageSections,
};
