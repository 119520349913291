/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import Section from '../../molecules/Section/Section';
import sanityImage from '../../../utils/sanityImage';
import { pageDimensions } from '../../../styles/base';
import { renderPageSections } from '../../templates/renderMultiSectionPageSections';

const MultiSection = ({ backgroundImage, sections, ...props }) => {
  /* TODO: Get default styles to work */
  const sectionCss = css`
    background-image: url(${sanityImage(backgroundImage)
      .auto('format')
      .width(pageDimensions.rawLargeDesktopWidth)
      .fit('max')
      .url()});
    background-repeat: no-repeat;
    background-size: ${pageDimensions.largeDesktopWidth};
  `;

  return (
    <Section css={sectionCss} {...props}>
      {renderPageSections(sections)}
    </Section>
  );
};

MultiSection.propTypes = {
  backgroundImage: PropTypes.shape({}),
  sections: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
};

MultiSection.defaultProps = {
  backgroundImage: {},
  sections: null,
};

export default MultiSection;
